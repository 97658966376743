import { IQsrOrder, OrderItemStatusEnum, OrderStatusEnum } from '@/views/QsrOrders/types';
import { IRestaurant, OrderPaymentMethodEnum, QsrCardViewIndicatorEnum } from '@/views/OrdersTableView/types';
import { Accordion, AccordionDetails, AccordionSummary, Paper, TableCell } from '@mui/material';
import { ExpandMoreRounded } from '@mui/icons-material';
import { QsrRows } from '@/components/QsrOrderDetails';
import { TableContent } from '@/components/common';
import { useTranslation } from '@/hooks/translations';
import { MenuCategoryResponse, MenuProductResponse } from '@/services/menu/types';
import QsrUserInfo from '@/components/QsrUserInfo';
import QsrOrderAction, { IOrderItemStatus } from '@/components/QsrOrderAction';
import QsrBatchStatus from '@/components/QsrBatchStatus';
import { QsrTableViewActions } from '@/components/QsrOrders/qsrTableViewActions';
import QsrOrderInfo from '@/components/QsrOrderInfo';
import QsrStatus from '@/components/QsrStatus';
import classNames from 'classnames';
import QsrDate from '@/components/QsrDate';
import { useMemo } from 'react';
import { getCurrencyWithAmount } from '@/common/utility';
import QsrDineOption from '@/components/QsrDineOption';

import { canDisplay } from '@/views/QsrOrders/utils';
import styles from './index.module.scss';

interface IProps {
    data: IQsrOrder;
    items: IQsrOrder[];
    currencyCode: string;
    currencySymbol: string;
    productMap: { [key: string]: MenuProductResponse };
    categoryMap: { [key: string]: MenuCategoryResponse };
    loading: boolean;
    restaurant: IRestaurant | null;
    changedStatusCount: number;
    lang: string;
    onBatchChange: (payload: IOrderItemStatus) => void;
    onDone: (status: OrderStatusEnum, ticketId: string, refId: string) => void;
    onPrint: (id: string) => void;
}

const QsrBatchCard = ({
    currencyCode,
    currencySymbol,
    productMap,
    categoryMap,
    loading,
    items,
    data,
    restaurant,
    changedStatusCount,
    lang,
    onDone,
    onPrint,
    onBatchChange,
}: IProps) => {
    const { t } = useTranslation('common');
    const priceRoundingMode = restaurant?.order_config?.priceRoundingMode;

    const canShow = useMemo(() => canDisplay(restaurant), [restaurant]);

    const startHeader = useMemo(() => {
        const isPager = data?.urlData?.tableId.startsWith('-');
        const indicator = isPager
            ? t('Pager {{no}}', { no: data?.urlData?.name || data?.urlData?.tableId.substring(1) })
            : t('Table {{no}}', { no: data?.urlData?.name || data?.urlData?.tableId });
        if (
            (isPager && canShow(QsrCardViewIndicatorEnum.Pager)) ||
            (!isPager && canShow(QsrCardViewIndicatorEnum.Table))
        ) {
            return (
                <>
                    <div className={styles.name}>{indicator}</div>
                    <div className={styles.divider}>/</div>
                </>
            );
        }
        return null;
    }, [data, restaurant]);

    const itemCount = items.length;

    const getOrderItemDetail = (item: IQsrOrder) => {
        return (
            <div className={classNames(styles.orders, styles.highlighted)}>
                <TableContent
                    headCells={
                        <>
                            <TableCell>{t('Item')}</TableCell>
                            <TableCell>{t('QTY')}</TableCell>
                            <TableCell align="right">{t('Price')}</TableCell>
                        </>
                    }
                >
                    <QsrRows
                        items={item?.orderData.items || []}
                        currencyCode={currencyCode}
                        currencySymbol={currencySymbol}
                        indent={0}
                        productMap={productMap}
                        categoryMap={categoryMap}
                        lang={lang}
                        loading={loading}
                        version={item?.orderData?.version}
                    />
                </TableContent>
                {item?.note && (
                    <div className={styles.note}>
                        <div className={styles.label}>{t('Note:')}</div>
                        <div className={styles.value}>{item.note}</div>
                    </div>
                )}
            </div>
        );
    };

    const getDetails = () => {
        if (
            (itemCount === 1 &&
                ![OrderPaymentMethodEnum.NoPayment, OrderPaymentMethodEnum.PayLater].includes(
                    restaurant?.order_config?.paymentMethod || OrderPaymentMethodEnum.Default,
                )) ||
            !restaurant?.order_config?.multiOrder
        ) {
            return <div className={styles.details}>{getOrderItemDetail(items[0])}</div>;
        }

        return items.map((item) => (
            <Accordion defaultExpanded={item.batchStatus === OrderItemStatusEnum.New} disableGutters elevation={0}>
                <AccordionSummary
                    className={styles.accordionHeader}
                    expandIcon={<ExpandMoreRounded className={styles.icon} />}
                >
                    <div className={styles.itemHeader}>
                        <div className={styles.info}>
                            <div className={styles.title}>{item.id}</div>
                            <QsrDate order={item} />
                        </div>
                        <div className={styles.label}>
                            <QsrBatchStatus status={item.batchStatus} />
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails className={styles.detailsContainer}>
                    <div className={styles.details}>
                        {getOrderItemDetail(item)}
                        <QsrOrderAction order={item} restaurant={restaurant} onChange={onBatchChange} />
                    </div>
                </AccordionDetails>
            </Accordion>
        ));
    };

    return (
        <Paper className={styles.container}>
            <div className={styles.header}>
                <div className={styles.info}>
                    <div className={styles.primary}>
                        {startHeader}
                        <div className={styles.price}>
                            {getCurrencyWithAmount(data.total, currencySymbol, currencyCode, true, priceRoundingMode)}
                        </div>
                    </div>
                    {canShow(QsrCardViewIndicatorEnum.RefId) && <div className={styles.secondary}>{data.refId}</div>}
                    {itemCount === 1 && (
                        <div className={styles.secondary}>
                            <strong>{t('Date:')}</strong>
                            <QsrDate order={data} />
                        </div>
                    )}
                    {data.ticketId && canShow(QsrCardViewIndicatorEnum.Ticket) && (
                        <div className={styles.ticket}>
                            <strong>{t('Ticket ID:')}</strong> {data.ticketId}
                        </div>
                    )}

                    <QsrDineOption order={data} />
                </div>
                <QsrStatus
                    order={data}
                    restaurant={restaurant}
                    itemsCount={itemCount}
                    changedStatusCount={changedStatusCount}
                />
            </div>
            {getDetails()}
            <QsrOrderInfo order={data} currencyCode={currencyCode} currencySymbol={currencySymbol} />
            <QsrUserInfo order={data} restaurant={restaurant} lang={lang} />
            {(itemCount === 1 || itemCount === changedStatusCount) && (
                <QsrTableViewActions data={data} restaurant={restaurant} onDone={onDone} onPrint={onPrint} />
            )}
        </Paper>
    );
};

export default QsrBatchCard;
